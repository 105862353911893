import { useFormik } from "formik"
import * as yup from "yup"
import React from "react"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { TextField, Button, auth } from "@project/shared"
import * as Sentry from "@sentry/node"
import { signInWithEmailAndPassword } from "firebase/auth"
import RestrictedRoute from "../../withRestrictedRoute"
import Link from "next/link"
import {
  UnAuthorizedHeader as Header,
  UnAuthorizedFooter as Footer,
} from "../../components"
import {
  catchExceptionCallback,
  Containeer,
  InputFieldWrapper,
  LoginWrapper,
  TextFieldWrapper,
  Wrapper,
} from "../../utils"
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons"

interface LoginType {
  email: string
  password: string
}

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [passwordVisible, setPasswordVisible] = React.useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(t("Enter Email")).required(t("Enter Email")),
    password: yup.string().required(t("Enter Password")),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })

  const handleLogin = async () => {
    setLoading(true)
    try {
      await signInWithEmailAndPassword(
        auth,
        formik.values.email,
        formik.values.password
      )
    } catch (error) {
      let message = error
      switch (error.code) {
        case "INVALID_LOGIN_CREDENTIALS":
          message = t("Wrong ID or password.")
          break
        case "auth/invalid-credential":
          message = t("Wrong ID or password.")
          break
        case "auth/wrong-password":
          message = t("Wrong ID or password.")
          break
        case "auth/user-not-found":
          message = t("Wrong ID or password.")
          break
        case "auth/user-disabled":
          message = t(
            "We could not login you at this moment. Please contact your administration for inquiry"
          )
          break
        case "auth/too-many-requests":
          message = t("Too many attempts to login.")
          break
        case "auth/invalid-email":
          message = t("Enter valid email.")
          break
        default:
          message = t("Wrong ID or password.")
          break
      }
      catchExceptionCallback(t(message))
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Head>
        <title>{t("施設管理画面ログイン")}</title>
      </Head>
      <Wrapper>
        <Header />
        <Containeer style={{ flex: 1, paddingBottom: "50px" }}>
          <div className={"top-content-wrapper"}>
            <div className={"logo-wrapper"}>
              <img src={"./assets/icons/logo.svg"} />
            </div>
            <h3>{t("Facility management login")}</h3>
          </div>
          <LoginWrapper>
            <TextFieldWrapper>
              <form onSubmit={formik.handleSubmit}>
                <InputFieldWrapper>
                  <TextField
                    name={"email"}
                    error={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Email")}
                    placeholder={t("Enter Email")}
                    className={"text-field"}
                    height={"40px"}
                    width={"340px"}
                    disableboxshadow
                    hasListStyle
                    listStyleLabelColor={"#FDD95F"}
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <div className={"password-field"}>
                    <TextField
                      name={"password"}
                      type={passwordVisible ? "text" : "password"}
                      error={formik.touched.password && formik.errors.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Password")}
                      placeholder={t("Enter Password")}
                      className={"text-field"}
                      height={"40px"}
                      width={"340px"}
                      disableboxshadow
                      hasListStyle
                      listStyleLabelColor={"#FDD95F"}
                    />

                    <span
                      className={"eye-icon"}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <EyeFilled /> : <EyeInvisibleFilled />}
                    </span>
                  </div>
                  <p className={"password-info-text"}>
                    {t("Passmust must be 6 - 16 characters")}
                  </p>
                </InputFieldWrapper>

                <div className={"login-button-wrapper"}>
                  <Button
                    htmlType={"submit"}
                    loading={loading}
                    background={"#FDD95F"}
                    bordercolor={"#FDD95F"}
                    color={"black"}
                    width={"169px"}
                    minheight={43}
                  >
                    {t("login")}
                  </Button>
                </div>

                <div className={"forget-password-wrapper"}>
                  <p>{t("Forgot your password ? ")}</p>
                  <Link href={"/forget-password"}>{t("reset")}</Link>
                </div>
              </form>
            </TextFieldWrapper>
          </LoginWrapper>
        </Containeer>
        <Footer />
      </Wrapper>
    </>
  )
}

export default RestrictedRoute(LoginPage)
